import API from 'utils/Axios';

export const fetchDeptartments = async () => {
  try {
    const res = await API.get(`/depts`);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const createDept = async (data) => {
  try {
    const res = await API.post(`/depts/new`, data);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
