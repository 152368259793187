import { useEffect, useState } from 'react';
import { DOCS_NAV, GEN_NAV, HR_NAV, SPRIME_NAV } from 'helpers/constants';
import { useSelector } from 'react-redux';

export const usePath = (pathname) => {
   const [nav, setNav] = useState([]);
   const [path, setPath] = useState('');

   const { user } = useSelector((store) => store.auth);

   useEffect(() => {
      let p = pathname.split('/');
      setPath(p);

      if (p[1] === 'human-resources') {
         setNav(HR_NAV);
         return;
      }
      if (p[1] === 'sprime') {
         setNav(SPRIME_NAV);
         return;
      }
      if (p[1] === 'docs') {
         setNav(
            DOCS_NAV.filter((e) =>
               // if allowedDeprtments are set, only show nav to users in those departments
               !e.allowedDepartments ? true : e.allowedDepartments.includes(user?.department?.name),
            ),
         );
         return;
      }

      setNav(GEN_NAV);
   }, [pathname]);

   return { navData: nav, path };
};
