import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ logo }) => {
  return <img src={logo} width="80%" height={100} style={{ objectFit: 'contain' }} />;
};

export default Image;

Image.propTypes = {
  logo: PropTypes.string,
};
