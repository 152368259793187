import styled from 'styled-components';

export const LazyContainer = styled.div`
  width: 100%;
  // height: 100vh;
  height: ${(props) => (props.height ? props.height : '100vh')};
  display: flex;
  align-items: center;
  justify-content: center;

  .lazy_image {
    width: 200px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const LayoutContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #000133;
  background-color: #f5f5f5;

  .children {
    width: ${(props) => (props.width === 'true' ? 'calc(100% - 155px)' : 'calc(100% - 270px)')};
    position: relative;
    left: ${(props) => (props.width === 'true' ? '155px' : '269px')};
    padding: 29px 59px 0 29px;
  }

  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex: 1;
  }
`;

export const SplashContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #092c4c;

  .lazy__initializer {
    width: 250px;
    height: 321px;

    .lazy__spinner {
      width: 250px;
      height: 250px;
      border-radius: 100%;
      border: 5px solid #828282;
      margin-bottom: 20px;
      padding: 20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    p {
      font-family: 'Nunito', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #ffffff;
      text-align: center;
      margin-top: 0.5rem;
    }
  }
`;
