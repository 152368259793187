import { FiBook, FiUser, FiUsers } from 'react-icons/fi';
import { MdOutlineDashboard } from 'react-icons/md';
import { BiArchiveIn, BiCalendar, BiLock, BiSupport } from 'react-icons/bi';
import { BsBarChart, BsClipboardData, BsPeople } from 'react-icons/bs';
import { IoAirplaneOutline, IoDocumentTextOutline, IoSettingsOutline } from 'react-icons/io5';
import { TbCertificate, TbCurrencyNaira, TbTemplate } from 'react-icons/tb';
import { AiOutlineFilePdf, AiOutlineUserAdd } from 'react-icons/ai';
import { GoLaw } from 'react-icons/go';
import { departments } from './enums';

export const RE_DIGIT = new RegExp(/^\d+$/);

export const GEN_NAV = [
   { href: '/dashboard', icon: MdOutlineDashboard, text: 'Overview' },
   { href: '/profile', icon: FiUser, text: 'Profile' },
   { href: '/support', icon: BiSupport, text: 'Support' },
];

export const HR_NAV = [
   { href: '/human-resources/dashboard', icon: MdOutlineDashboard, text: 'Overview' },
   { href: '/human-resources/employees', icon: FiUsers, text: 'Employees' },
   // { href: '/human-resources/add-employee', icon: AiOutlineUserAdd, text: 'Add Employee' },
   { href: '/human-resources/permissions', icon: BiLock, text: 'Manage Permissions' },
   { href: '/human-resources/archive', icon: BiArchiveIn, text: 'Archive' },
];

export const SPRIME_NAV = [
   { href: '/sprime/dashboard', icon: MdOutlineDashboard, text: 'Overview' },
   {
      href: '/sprime/flight-schedule',
      icon: BiCalendar,
      text: 'Flight Schedule',
      subMenus: [
         {
            href: '/sprime/flight-schedule/scheduled',
            icon: BiCalendar,
            text: 'Scheduled',
         },
         {
            href: '/sprime/flight-schedule/approved',
            icon: BiCalendar,
            text: 'Approved',
         },
         { href: '/sprime/flight-schedule/history', icon: BiCalendar, text: 'History' },
      ],
   },
   {
      href: '/sprime/flight-log',
      icon: BsClipboardData,
      text: 'Flight Log',
      subMenus: [
         {
            href: '/sprime/flight-log/logs',
            // icon: BiCalendar,
            text: 'Logs',
         },
         {
            href: '/sprime/flight-log/drafts',
            // icon: BiCalendar,
            text: 'Drafts',
         },
      ],
   },
   { href: '/sprime/incidence-report', icon: BsBarChart, text: 'Incidence Report' },
   { href: '/sprime/tools', icon: IoSettingsOutline, text: 'Flight Tools' },
   // { href: '/sprime/staff-schedule', icon: FiUsers, text: 'Staff Schedule' },
];

export const DOCS_NAV = [
   { href: '/docs/dashboard', icon: MdOutlineDashboard, text: 'Overview' },
   {
      href: '/docs/templates',
      icon: TbTemplate,
      text: 'Templates',
   },
   { href: '/docs/documents', icon: AiOutlineFilePdf, text: 'Documents' },
   { href: '/docs/manuals', icon: FiBook, text: 'Manuals' },
   { href: '/docs/certifications', icon: TbCertificate, text: 'Certfications' },
   {
      href: '/docs/legal',
      icon: GoLaw,
      text: 'Legal',
      subMenus: [
         { href: '/docs/legal/licenses', text: 'Licenses' },
         { href: '/docs/legal/contracts', text: 'Contracts' },
      ],
      allowedDepartments: [departments.LEGAL],
   },

   // { href: '/docs/track', icon: FiMapPin, text: 'Track' },
];

export const APP_ID = {
   SAH_HR: 'sahco-hr',
   SAH_DOCS: 'sahco-docs',
   SAH_BUDGET: 'sahco-budget',
   S_PRIME: 'sahco-prime',
};

export const navLinks = [
   {
      title: 'Dashboard',
      path: '/dashboard',
      basePath: '',
      icon: MdOutlineDashboard,
      subMenus: GEN_NAV,
      show: true,
   },
   {
      title: 'SahHR',
      path: '/human-resources/dashboard',
      basePath: '/human-resources',
      icon: BsPeople,
      subMenus: HR_NAV,
      permission: APP_ID.SAH_HR,
      show: true,
   },
   {
      title: 'SahDocs',
      path: '/docs/dashboard',
      basePath: '/docs',
      subMenus: DOCS_NAV,
      icon: IoDocumentTextOutline,
      permission: APP_ID.SAH_DOCS,
      show: true,
   },
   {
      title: 'SahBudget',
      path: '/budget/dashboard',
      basePath: '/budget',
      icon: TbCurrencyNaira,
      permission: APP_ID.SAH_BUDGET,
      show: false,
   },
   {
      title: 'SPrime',
      path: '/sprime/dashboard',
      basePath: '/sprime',
      icon: IoAirplaneOutline,
      subMenus: SPRIME_NAV,
      permission: APP_ID.S_PRIME,
      show: true,
   },
];

export const invalidApiKeyCodes = [401];
