import {
   copyPermission,
   createAction,
   fetchEmployeeActions,
} from 'modules/humanresource/services/actions.service';
import { setHRLoading } from '.';
import * as type from '../types/types';
import { toast } from 'react-toastify';

export const fetchActions = () => async (dispatch) => {
   dispatch(setHRLoading(true));
   try {
      const { data } = await fetchEmployeeActions();
      dispatch({
         type: type.GET_ACTIONS,
         payload: data,
      });
      dispatch(setHRLoading(false));
   } catch (e) {
      dispatch(setHRLoading(false));
      // toast.error('Unable to fetch permissions' || e?.message);
   }
};

export const duplicatePermission = (data) => async (dispatch) => {
   dispatch(setHRLoading(true));
   try {
      const { success, message } = await copyPermission(data);
      dispatch({
         type: type.CLONE_PERMISSION,
      });
      if (success) {
         dispatch(setHRLoading(false));
         toast.success('Permission copied successfully' || message);
      }
   } catch (e) {
      dispatch(setHRLoading(false));
      toast.error('Unable to copy permissions' || e?.message);
   }
};

export const createPermission = (data) => async (dispatch) => {
   dispatch({
      type: type.CREATE_ACTION_START,
      payload: true,
   });
   try {
      const { success, message } = await createAction(data);
      dispatch({
         type: type.CREATE_ACTION,
      });
      if (success) {
         dispatch({
            type: type.CREATE_ACTION_START,
            payload: false,
         });
         toast.success(message);
         dispatch(fetchActions());
      }
   } catch (e) {
      dispatch({
         type: type.CREATE_ACTION_START,
         payload: false,
      });
      toast.error('Unable to create permissions' || e?.message);
   }
};
