import React from 'react';
import { LazyContainer } from './styles';
import logo from 'assets/images/data.svg';
import PropTypes from 'prop-types';
import ScaleLoader from 'react-spinners/ScaleLoader';

const Lazy = () => {
  return (
    <LazyContainer>
      <div className="lazy_image">
        <img alt="Lazy Loader Logo" src={logo} />
      </div>
    </LazyContainer>
  );
};

export default Lazy;

export const APILoading = ({ loading, height, iconHeight }) => {
  return (
    <LazyContainer height={height}>
      <div className="lazy_image">
        <ScaleLoader color="#EB5757" loading={loading} height={iconHeight || 60} />
      </div>
    </LazyContainer>
  );
};

APILoading.propTypes = {
  loading: PropTypes.bool,
  iconHeight: PropTypes.number,
  height: PropTypes.string,
};
