import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'redux/store/index';
import Lazy from 'layout/Lazy';
import { getUserPermissions, setHome, setIsAuth } from 'redux/action';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { isProduction } from 'helpers/utility';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
const token = localStorage.getItem('token');
const permId = localStorage.getItem('permId');
const apiKey = localStorage.getItem('apiKey');
const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

if (token) {
   store.dispatch(setIsAuth());
}
if (permId && apiKey) {
   store.dispatch(getUserPermissions(permId));
}
store.dispatch(setHome());

const rollbarConfig = {
   accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
   environment: process.env.NODE_ENV,
   captureUncaught: isProduction(),
   captureUnhandledRejections: isProduction(),
   payload: {
      source_map_enabled: isProduction(),
      // code_version:
      guess_uncaught_frames: isProduction(),
      person: {
         id: user?._id,
         email: user?.companyEmail,
         username: user?.fullName,
      },
   },
   enabled: isProduction(),
};

posthog.init('phc_wrmSTfqb0DrxruQy60tHfmvRSo8i5OXuikMfcepFV45', {
   api_host: 'https://us.i.posthog.com',
   person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
});

root.render(
   <React.StrictMode>
      <Provider store={store}>
         <PersistGate loading={<Lazy />} persistor={persistor}>
            <RollbarProvider config={rollbarConfig}>
               <ErrorBoundary>
                  <BrowserRouter>
                     <PostHogProvider client={posthog}>
                        <App />
                     </PostHogProvider>
                  </BrowserRouter>
               </ErrorBoundary>
            </RollbarProvider>
         </PersistGate>
      </Provider>
   </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
