import './App.css';
import { SnackbarProvider } from 'notistack';
import AppRoutes from 'routes/AppRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'components/utils/Loader';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NextUIProvider } from '@nextui-org/react';

function App() {
   const queryClient = new QueryClient({
      defaultOptions: {
         queries: {
            refetchOnWindowFocus: false,
            //  networkMode: "always",
         },
         // mutations: {
         //   networkMode: "always",
         // },
      },
   });

   return (
      <NextUIProvider>
         <QueryClientProvider client={queryClient}>
            <ToastContainer
               autoClose={3000}
               hideProgressBar={true}
               newestOnTop={true}
               theme="dark"
            />
            <SnackbarProvider>
               <Loader />
               <AppRoutes />
               <ReactQueryDevtools />
            </SnackbarProvider>
         </QueryClientProvider>
      </NextUIProvider>
   );
}

export default App;
