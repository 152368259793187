export const employeeStatus = {
   ACTIVE: 'ACTIVE',
   SUSPENDED: 'SUSPENDED',
   DEACTIVATED: 'DEACTIVATED',
};

export const permissions = {
   CAN_EDIT_ANYTHING: 'super-edit',
   CAN_VIEW_ANYTHING: 'super-view',
   CAN_CREATE_ANYTHING: 'super-create',
   CAN_DELETE_ANYTHING: 'super-delete',
   CAN_EDIT_FLIGHTLOG: 'edit-flightLog',
   CAN_CREATE_FLIGHTLOG: 'create-flightlog',
   CAN_EDIT_AIRLINE: 'edit-airline',
   CAN_VIEW_AUDIT: 'view-audit',
   CAN_VIEW_FLIGHTLOG: 'view-flightLog',
   CAN_VIEW_SCHEDULE: 'view-schedule',
   CAN_EDIT_SCHEDULE: 'edit-schedule',
   CAN_CREATE_EMPLOYEE: 'create-employee',
   CAN_CREATE_AIRLINE: 'create-airline',
   CAN_VIEW_AIRLINE: 'view-airline',
   CAN_VIEW_DAMAGED_BAGS: 'view-damaged-bags',
   CAN_CREATE_DAMAGED_BAGS: 'create-damaged-bags',
   CAN_EDIT_DAMAGED_BAGS: 'edit-damaged-bags',
   CAN_APPROVE_DOCUMENTS: 'approve-document',
   CAN_CREATE_LICENSE: 'create-license',
   CAN_CREATE_CONTRACT: 'create-contract',
};

export const otpPurpose = {
   PIN: 'PIN',
   PASSWORD: 'PASSWORD',
};

export const departments = {
   CARGO: 'CARGO',
   MARKETING: 'MARKETING',
   MD_CEO: 'MD/CEO',
   SAFETY: 'SAFETY',
   BILLINGS: 'BILLINGS',
   ENG: 'ENG',
   OPERATIONS: 'OPERATIONS',
   HUMAN_RESOURCES: 'HUMAN RESOURCES',
   INTERNAL_AUDIT: 'INTERNAL AUDIT',
   INTERNAL_CONTROL: 'INTERNAL CONTROL',
   ADMIN: 'ADMIN',
   FINANCE: 'FINANCE',
   LEGAL: 'LEGAL',
   SECURITY: 'SECURITY',
   BUS_DEVT: 'BUS DEVT',
   PUBLIC_COMM: 'PUBLIC COMM',
   TECHNICAL: 'TECHNICAL',
   PROCUREMENT: 'PROCUREMENT',
   IT: 'IT',
   BILLING: 'BILLING',
   PUBLIC_COMMUNICATIONS: 'PUBLIC COMMUNICATIONS',
};
