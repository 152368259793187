import { initialState } from 'redux/reducers/initialState';
import * as type from '../types/types';

const appReducer = (state = initialState.sPrime, action) => {
  switch (action.type) {
    case type.GET_FLIGHTS:
      return {
        ...state,
        flights: { ...state.flights, loading: true },
      };
    case type.GET_FLIGHTS_SUCCESS:
      return {
        ...state,
        flights: { ...state.flights, flights_data: action.payload, loading: false, success: true },
      };
    case type.GET_FLIGHTS_ERROR:
      return {
        ...state,
        flights: { ...state.flights, loading: false, success: false, error: action.payload },
      };

    case type.GET_SINGLE_FLIGHT:
      return {
        ...state,
        flight: { ...state.flight, loading: true },
      };
    case type.GET_SINGLE_FLIGHT_SUCCESS:
      return {
        ...state,
        flight: { ...state.flight, flight_data: action.payload, loading: false, success: true },
      };
    case type.CLEAR_SINGLE_FLIGHT:
      return {
        ...state,
        flight: { ...state.flight, flight_data: null, loading: false, success: null },
      };
    case type.GET_SINGLE_FLIGHT_ERROR:
      return {
        ...state,
        flight: { ...state.flight, loading: false, success: false, error: action.payload },
      };

    case type.CREATE_FLIGHT:
      return {
        ...state,
        flight: { ...state.flight, create_loading: true },
      };
    case type.CREATE_FLIGHT_SUCCESS:
      return {
        ...state,
        flight: { ...state.flight, create_loading: false, success: true },
      };
    case type.CREATE_FLIGHT_ERROR:
      return {
        ...state,
        flight: { ...state.flight, create_loading: false, success: false, error: action.payload },
      };
    case type.CLEAR_FLIGHT_DATA:
      return {
        ...state,
        flight: state.flight,
      };

    case type.FETCH_AIRLINES:
      return {
        ...state,
        airlines: { ...state.airlines, airline_loading: true },
      };
    case type.FETCH_AIRLINES_SUCCESS:
      return {
        ...state,
        airlines: { ...state.airlines, airline_data: action.payload, airline_loading: false, success: true },
      };
    case type.FETCH_AIRLINES_ERROR:
      return {
        ...state,
        airlines: { ...state.airlines, airline_loading: false, success: false, error: action.payload },
      };

    //Flight Logs
    case type.GET_FLIGHT_LOGS:
      return {
        ...state,
        logs: { ...state.logs, loading: true },
      };
    case type.GET_FLIGHT_LOGS_SUCCESS:
      return {
        ...state,
        logs: { ...state.logs, logs_data: action.payload, loading: false, success: true },
      };
    case type.GET_FLIGHT_LOGS_ERROR:
      return {
        ...state,
        logs: { ...state.logs, loading: false, success: false, error: action.payload },
      };

    case type.GET_SINGLE_FLIGHT_LOG:
      return {
        ...state,
        log: { ...state.log, loading: true },
      };
    case type.GET_SINGLE_FLIGHT_LOG_SUCCESS:
      return {
        ...state,
        log: { ...state.log, log_data: action.payload, loading: false, success: true },
      };
    case type.GET_SINGLE_FLIGHT_LOG_ERROR:
      return {
        ...state,
        log: { ...state.log, loading: false, success: false, error: action.payload },
      };

    case type.CREATE_FLIGHT_LOG:
      return {
        ...state,
        log: { ...state.log, create_loading: true },
      };
    case type.CREATE_FLIGHT_LOG_SUCCESS:
      return {
        ...state,
        log: { ...state.log, create_loading: false, success: true },
      };
    case type.CREATE_FLIGHT_LOG_ERROR:
      return {
        ...state,
        log: { ...state.log, create_loading: false, success: false, error: action.payload },
      };

    //Incident Report
    case type.GET_REPORTS:
      return {
        ...state,
        reports: { ...state.reports, loading: true },
      };
    case type.GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: { ...state.reports, reports_data: action.payload, loading: false, success: true },
      };
    case type.GET_REPORTS_ERROR:
      return {
        ...state,
        reports: { ...state.reports, loading: false, success: false, error: action.payload },
      };

    case type.GET_SINGLE_REPORT:
      return {
        ...state,
        report: { ...state.report, loading: true },
      };
    case type.GET_SINGLE_REPORT_SUCCESS:
      return {
        ...state,
        report: { ...state.report, report_data: action.payload, loading: false, success: true },
      };
    case type.GET_SINGLE_REPORT_ERROR:
      return {
        ...state,
        report: { ...state.report, loading: false, success: false, error: action.payload },
      };

    case type.CREATE_REPORT:
      return {
        ...state,
        report: { ...state.report, create_loading: true },
      };
    case type.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        report: { ...state.report, create_loading: false, success: true },
      };
    case type.CREATE_REPORT_ERROR:
      return {
        ...state,
        report: { ...state.report, create_loading: false, success: false, error: action.payload },
      };

    case type.ADD_DAMAGED_BAGS:
      return {
        ...state,
        bag: { ...state.bag, create_loading: true },
      };
    case type.ADD_DAMAGED_BAGS_SUCCESS:
      return {
        ...state,
        bag: { ...state.bag, create_loading: false, success: true },
      };
    case type.ADD_DAMAGED_BAGS_ERROR:
      return {
        ...state,
        bag: { ...state.bag, create_loading: false, success: false, error: action.payload },
      };

    case type.GET_DAMAGED_BAGS:
      return {
        ...state,
        bags: { ...state.bags, loading: true },
      };
    case type.GET_DAMAGED_BAGS_SUCCESS:
      return {
        ...state,
        bags: { ...state.bags, bags_data: action.payload, loading: false, success: true },
      };
    case type.GET_DAMAGED_BAGS_ERROR:
      return {
        ...state,
        bags: { ...state.bags, loading: false, success: false, error: action.payload },
      };

    default:
      return { ...state };
  }
};

export default appReducer;
