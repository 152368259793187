// ----- Permissions ----

// Stations
export const GET_STATIONS = 'GET_STATIONS';
export const CREATE_STATION = 'CREATE_STATION';
export const CREATE_STATION_START = 'CREATE_STATION_START';
export const CREATE_STATION_ERROR = 'CREATE_STATION_ERROR';

//Departments
export const GET_DEPARTMENT = 'GET_DEPARTMENT';
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const CREATE_DEPARTMENT_START = 'CREATE_DEPARTMENT_START';
export const CREATE_DEPARTMENT_ERROR = 'CREATE_DEPARTMENT_ERROR';

//Actions
export const GET_ACTIONS = 'GET_ACTIONS';
export const CREATE_ACTION = 'CREATE_ACTION';
export const CREATE_ACTION_START = 'CREATE_ACTION_START';
export const CREATE_ACTION_ERROR = 'CREATE_ACTION_ERROR';

//Jobs
export const GET_JOB_TITLE = 'GET_JOB_TITLE';
export const CREATE_JOB_TITLE = 'CREATE_JOB_TITLE';
export const CREATE_JOB_TITLE_START = 'CREATE_JOB_TITLE_START';
export const CREATE_JOB_TITLE_ERROR = 'CREATE_JOB_TITLE_ERROR';

//Modules
export const GET_PERMITTED_MODULES = 'GET_PERMITTED_MODULES';
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const GET_MODULES_ERROR = 'GET_MODULES_ERROR';

// Permission
export const EDIT_PERMISSIONS = 'EDIT_PERMISSIONS';

//Employees
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_ARCHIVED_EMPLOYEES = 'GET_ARCHIVED_EMPLOYEES';

export const VIEW_EMPLOYEE_START = 'VIEW_EMPLOYEE_START';
export const VIEW_EMPLOYEE = 'VIEW_EMPLOYEE';
export const VIEW_EMPLOYEE_ERROR = 'VIEW_EMPLOYEE_ERROR';

export const CREATE_EMPLOYEE_START = 'CREATE_EMPLOYEE_START';
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const CREATE_EMPLOYEE_ERROR = 'CREATE_EMPLOYEE_ERROR';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';

export const EDIT_EMPLOYEE_START = 'EDIT_EMPLOYEE_START';
export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE';
export const EDIT_EMPLOYEE_ERROR = 'EDIT_EMPLOYEE_ERROR';

export const UPLOAD_EMPLOYEE = 'UPLOAD_EMPLOYEE';
export const UPLOAD_EMPLOYEE_ERROR = 'UPLOAD_EMPLOYEE_ERROR';
export const UPLOAD_EMPLOYEE_SUCCESS = 'UPLOAD_EMPLOYEE_SUCCESS';

export const DELETE_EMPLOYEES = 'DELETE_EMPLOYEES';
export const DELETE_EMPLOYEE_ERROR = 'DELETE_EMPLOYEE_ERROR';

export const DEACTIVATE_EMPLOYEES = 'DEACTIVATE_EMPLOYEES';
export const DEACTIVATE_EMPLOYEE_ERROR = 'DEACTIVATE_EMPLOYEE_ERROR';

export const CLONE_PERMISSION = 'CLONE_PERMISSION';

export const ERROR = 'ERROR';
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';

export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
