import { fetchJobs } from 'modules/humanresource/services/jobs.service';
import { setHRLoading } from '.';
import * as type from '../types/types';
import { toast } from 'react-toastify';

export const fetchJobTitles = () => async (dispatch) => {
  dispatch(setHRLoading(true));
  try {
    const { data } = await fetchJobs();
    dispatch({
      type: type.GET_JOB_TITLE,
      payload: data,
    });
    dispatch(setHRLoading(false));
  } catch (e) {
    dispatch(setHRLoading(false));
    toast.error(e?.message || 'Unable to get job titles');
  }
};
