import styled from 'styled-components';
import Pattern from 'assets/images/Pattern.svg';

export const Page404Container = styled.div`
  padding: 30px;
  background-image: url(${Pattern});

  .not_found_container {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .imgContainer {
      width: 697px;
      height: 462.82px;
      max-width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    h2 {
      width: auto;
      height: 39px;
      padding-top: 30px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 38px;
      color: #292929;
      margin: 0 auto 0 auto;
    }
  }

  .formBtn {
    margin: 60px auto 0 auto;
  }
`;
