import { combineReducers } from 'redux';
import authReducer from './index';
import employeesReducer from 'modules/humanresource/redux/reducer/employees';
import flightsReducer from 'modules/sprime/redux/reducer/flights';

const rootReducer = combineReducers({
  auth: authReducer,
  employees: employeesReducer,
  flights: flightsReducer,
});

export default rootReducer;
