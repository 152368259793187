import React, { Suspense, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'redux/action';
import Cookies from 'js-cookie';

/**
 * AuthRoute is a higher-order component that checks the user's authentication and authorization status before rendering its child components.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - children: React components to be rendered if the user is authenticated and authorized.
 *   - role: (optional) An array of roles that the user must have to access the child components. Defaults to an empty array.
 *   - lazy: (optional) A React component to be rendered while the child components are being loaded. Defaults to an empty component.
 *   - isAuth: (optional) A boolean indicating whether the user must be authenticated to access the child components. Defaults to true.
 * @return {JSX.Element} The child components if the user is authenticated and authorized, otherwise a Navigate component to the login page.
 */
const AuthRoute = ({ children, role = [], lazy: Lazy, isAuth }) => {
   const apiKey = localStorage.getItem('apiKey');
   const session = Cookies.get('API_KEY');

   const pathname = useLocation().pathname;

   const { user } = useSelector((store) => store.auth);
   const dispatch = useDispatch();

   const accessGranted =
      role.length === 0 ||
      role.includes(user?.department?.name) ||
      role.includes(user?.currentStation?.code);

   useEffect(() => {
      if (!apiKey || !session) {
         dispatch(signOut()); // clear the state in redux
      }
   }, [apiKey, session]);

   if (isAuth && !accessGranted) {
      return <Navigate to="/dashboard" />;
   }

   // If apiKey or session are missing, redirect to login page
   // @param apiKey - apiKey from localstorage
   // @param session - session cookie from browser
   // @returns JSX - navigates to login page

   if (!apiKey || !session) {
      return <Navigate to={pathname ? `/login?redirect=${pathname}` : '/login'} />; // redirect to login page if apiKey or session are missing
   }

   return <Suspense fallback={<Lazy />}>{children}</Suspense>;
};

AuthRoute.propTypes = {
   children: PropTypes.node,
   lazy: PropTypes.any,
   role: PropTypes.array,
   isAuth: PropTypes.bool,
};

export default AuthRoute;
