import * as type from '../types/types';
import {
   addDamagedBags,
   fetchAirlines,
   fetchDamagedBags,
   fetchFlightLogs,
   fetchFlightSchedule,
   fetchReports,
   fetchSingleFlight,
   fetchSingleFlightLog,
   fetchSingleReport,
   makeFlightLog,
   makeFlightSchedule,
   makeReport,
   updateFlightLog,
   updateFlightSchedule,
   updateReport,
} from 'modules/sprime/services/flights.service';
import { toast } from 'react-toastify';

export const getFlightSchedule =
   ({
      page,
      limit,
      mode,
      status,
      code,
      carrier,
      startDate,
      endDate,
      defaultFlight,
      flightNumber,
   }) =>
   async (dispatch) => {
      dispatch({ type: type.GET_FLIGHTS });
      try {
         const { data, success } = await fetchFlightSchedule({
            page,
            limit,
            mode,
            status,
            code,
            carrier,
            startDate,
            endDate,
            defaultFlight,
            flightNumber,
         });

         if (success) {
            dispatch({
               type: type.GET_FLIGHTS_SUCCESS,
               payload: data,
            });
         }
      } catch (e) {
         dispatch({ type: type.GET_FLIGHTS_ERROR });
         toast.error(e?.message || 'Unable to get Flight Schedule');
      }
   };

export const getSingleFlight = (id) => async (dispatch) => {
   dispatch({ type: type.GET_SINGLE_FLIGHT });
   try {
      const { data, success } = await fetchSingleFlight(id);

      if (success) {
         dispatch({
            type: type.GET_SINGLE_FLIGHT_SUCCESS,
            payload: data,
         });
      }
   } catch (e) {
      dispatch({ type: type.GET_SINGLE_FLIGHT_ERROR });
      toast.error(e?.message || 'Unable to get Flight Schedules');
   }
};

export const createFlightSchedule =
   ({ payload, handleSuccess }) =>
   async (dispatch) => {
      dispatch({ type: type.CREATE_FLIGHT });
      try {
         const { success } = await makeFlightSchedule({ payload });

         if (success) {
            dispatch({
               type: type.CREATE_FLIGHT_SUCCESS,
            });
            handleSuccess();
            toast.success('Flight scheduled successfully');
         }
      } catch (e) {
         dispatch({ type: type.CREATE_FLIGHT_ERROR });
         toast.error(e?.message || 'Unable to create Flight Schedule');
      }
   };

export const editFlightSchedule =
   ({ payload, id, handleSuccess }) =>
   async (dispatch) => {
      dispatch({ type: type.CREATE_FLIGHT });
      try {
         const { data, success } = await updateFlightSchedule({ payload, id });

         if (success) {
            dispatch({
               type: type.CREATE_FLIGHT_SUCCESS,
               payload: data,
            });
            toast.success('Updated Successfully');
            if (handleSuccess) {
               handleSuccess();
            }
         }
      } catch (e) {
         dispatch({ type: type.CREATE_FLIGHT_ERROR });
         toast.error(e?.message || 'Unable to update Flight Schedule');
      }
   };

export const getAirlines = () => async (dispatch) => {
   dispatch({ type: type.FETCH_AIRLINES });
   try {
      const { data, success } = await fetchAirlines();

      if (success) {
         dispatch({
            type: type.FETCH_AIRLINES_SUCCESS,
            payload: data,
         });
      }
   } catch (e) {
      dispatch({ type: type.FETCH_AIRLINES_ERROR });
      toast.error(e?.message || 'Unable to fetch airlines');
   }
};

//Flight Logs
export const getFlightLogs =
   ({
      page,
      limit,
      flightSupervisor,
      operator,
      modeOfOperation,
      startDate,
      endDate,
      flightNumber,
   }) =>
   async (dispatch) => {
      dispatch({ type: type.GET_FLIGHT_LOGS });
      try {
         const { data, success } = await fetchFlightLogs({
            page,
            limit,
            flightSupervisor,
            operator,
            modeOfOperation,
            startDate,
            endDate,
            flightNumber,
         });

         if (success) {
            dispatch({
               type: type.GET_FLIGHT_LOGS_SUCCESS,
               payload: data,
            });
         }
      } catch (e) {
         dispatch({ type: type.GET_FLIGHT_LOGS_ERROR });
         toast.error(e?.message || 'Unable to get Flight Logs');
      }
   };

export const getSingleFlightLog = (id) => async (dispatch) => {
   dispatch({ type: type.GET_SINGLE_FLIGHT_LOG });
   try {
      const { data, success } = await fetchSingleFlightLog(id);

      if (success) {
         dispatch({
            type: type.GET_SINGLE_FLIGHT_LOG_SUCCESS,
            payload: data,
         });
      }
   } catch (e) {
      dispatch({ type: type.GET_SINGLE_FLIGHT_LOG_ERROR });
      toast.error(e?.message || 'Unable to get Flight Log');
   }
};

export const createFlightLog =
   ({ payload, handleSuccess }) =>
   async (dispatch) => {
      dispatch({ type: type.CREATE_FLIGHT_LOG });
      try {
         const { success } = await makeFlightLog({ payload });

         if (success) {
            dispatch({
               type: type.CREATE_FLIGHT_LOG_SUCCESS,
            });
            handleSuccess();
            toast.success('Flight Log created successfully');
         }
      } catch (e) {
         dispatch({ type: type.CREATE_FLIGHT_LOG_ERROR });
         toast.error(e || 'Unable to create Flight Log');
      }
   };

export const editFlightLog =
   ({ payload, id, editSuccess }) =>
   async (dispatch) => {
      dispatch({ type: type.CREATE_FLIGHT_LOG });
      try {
         const { data, success } = await updateFlightLog({ payload, id });

         if (success) {
            dispatch({
               type: type.CREATE_FLIGHT_LOG_SUCCESS,
               payload: data,
            });
            toast.success('Updated Successfully');
            if (editSuccess) {
               editSuccess();
            }
         }
      } catch (e) {
         dispatch({ type: type.CREATE_FLIGHT_LOG_ERROR });
         toast.error(e || 'Unable to update Flight Log');
      }
   };

//Incident Report
export const getReports =
   ({ page, limit, nameOfSupervisor, damageCaused, operator, startDate, endDate }) =>
   async (dispatch) => {
      dispatch({ type: type.GET_REPORTS });
      try {
         const { data, success } = await fetchReports({
            page,
            limit,
            nameOfSupervisor,
            damageCaused,
            operator,
            startDate,
            endDate,
         });

         if (success) {
            dispatch({
               type: type.GET_REPORTS_SUCCESS,
               payload: data,
            });
         }
      } catch (e) {
         dispatch({ type: type.GET_REPORTS_ERROR });
         toast.error(e?.message || 'Unable to get Incident Reports');
      }
   };

export const getSingleReport = (id) => async (dispatch) => {
   dispatch({ type: type.GET_SINGLE_REPORT });
   try {
      const { data, success } = await fetchSingleReport(id);

      if (success) {
         dispatch({
            type: type.GET_SINGLE_REPORT_SUCCESS,
            payload: data,
         });
      }
   } catch (e) {
      dispatch({ type: type.GET_SINGLE_REPORT_ERROR });
      toast.error(e?.message || 'Unable to get Incident Report');
   }
};

export const createReport =
   ({ payload, id, handleSuccess }) =>
   async (dispatch) => {
      dispatch({ type: type.CREATE_REPORT });
      try {
         const { success } = await makeReport({ payload, id });

         if (success) {
            dispatch({
               type: type.CREATE_REPORT_SUCCESS,
            });
            handleSuccess();
            toast.success('Incidence Report created successfully');
         }
      } catch (e) {
         dispatch({ type: type.CREATE_REPORT_ERROR });
         toast.error(e?.message || 'Unable to create Incident Report');
      }
   };

export const editReport =
   ({ payload, id, logId, editSuccess }) =>
   async (dispatch) => {
      dispatch({ type: type.CREATE_REPORT });
      try {
         const { data, success } = await updateReport({ payload, id, logId });

         if (success) {
            dispatch({
               type: type.CREATE_REPORT_SUCCESS,
               payload: data,
            });
            toast.success('Updated Successfully');
            if (editSuccess) {
               editSuccess();
            }
         }
      } catch (e) {
         dispatch({ type: type.CREATE_REPORT_ERROR });
         toast.error(e?.message || 'Unable to update Incident Report');
      }
   };

//Damaged Bags
export const createDamagedBags =
   ({ payload, handleSuccess }) =>
   async (dispatch) => {
      dispatch({ type: type.ADD_DAMAGED_BAGS });
      try {
         const { success } = await addDamagedBags({ payload });

         if (success) {
            dispatch({
               type: type.ADD_DAMAGED_BAGS_SUCCESS,
            });
            handleSuccess();
            toast.success('Damaged Bags added successfully');
         }
      } catch (e) {
         dispatch({ type: type.ADD_DAMAGED_BAGS_ERROR });
         toast.error(e?.message || 'Unable to add Damaged Bags');
      }
   };

export const getDamagedBags =
   ({ page, limit, flightScheduleId, flightLogId }) =>
   async (dispatch) => {
      dispatch({ type: type.GET_DAMAGED_BAGS });
      try {
         const { data, success } = await fetchDamagedBags({
            page,
            limit,
            flightScheduleId,
            flightLogId,
         });

         if (success) {
            dispatch({
               type: type.GET_DAMAGED_BAGS_SUCCESS,
               payload: data,
            });
         }
      } catch (e) {
         dispatch({ type: type.GET_DAMAGED_BAGS_ERROR });
         toast.error(e?.message || 'Unable to get Damaged Bags');
      }
   };
