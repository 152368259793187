import { setHRLoading } from '.';
import * as type from '../types/types';
import { toast } from 'react-toastify';
import { getModules } from 'modules/humanresource/services/modules.service';

export const fetchModules = () => async (dispatch) => {
   dispatch(setHRLoading(true));
   try {
      const { data } = await getModules();
      dispatch({
         type: type.GET_MODULES_SUCCESS,
         payload: data,
      });
      dispatch(setHRLoading(false));
   } catch (e) {
      dispatch(setHRLoading(false));
      // toast.error(e?.message || 'Unable to get modules');
   }
};
