import moment from 'moment';
import { useSelector } from 'react-redux';

export const closePopupMenu = () => {
   const elem = document.activeElement;
   if (elem) {
      elem?.blur();
   }
};

export const canViewApp = (permission) => {
   const permittedModules = useSelector((store) => store.auth.permittedModules);
   return permittedModules?.includes(permission) ? true : false;
};

export const isProduction = () => process.env.NODE_ENV === 'production';
// const isProduction = () =>
//    process.env.NODE_ENV === 'production' && process.env.REACT_APP_PUBLIC_ENV === 'production'; //this will return true if we're in production environment and not staging environment

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const isArrayOfStrings = (arr) => {
   if (!Array.isArray(arr)) {
      return false; // Not an array
   }

   for (let item of arr) {
      if (typeof item !== 'string') {
         return false; // Array contains non-string elements
      }
   }

   return true; // It's an array of strings
};

export const formattedDate = (originalDate) => moment(originalDate).format('MMMM DD, YYYY h:mma');

export const convertToLowercase = (text) => text.toLowerCase();

export const maskEmail = (email) => {
   if (!email) return;
   // Split the email address into the username and domain parts
   const [username, domain] = email?.split('@');

   // If the username is less than or equal to 3 characters, we don't need to mask it
   if (username?.length <= 5) {
      return email;
   }

   // Create the masked username
   const maskedUsername = username?.slice(0, 5) + '*'.repeat(username?.length - 5);

   // Combine the masked username with the domain
   return `${maskedUsername}@${domain}`;
};

export function getFirstTwoInitials(name) {
   // Split the name into an array of words
   if (!name) return;
   const nameParts = name.split(' ');
   // Initialize an empty array to hold the initials
   const initials = [];
   // Loop through the name parts and get the first character of each part
   for (let i = 0; i < nameParts.length; i++) {
      if (nameParts[i]) {
         initials.push(nameParts[i][0].toUpperCase());
      }
   }
   // Join the first two initials and return them
   return initials.slice(0, 2).join('');
}
