import { createDept, fetchDeptartments } from 'modules/humanresource/services/dept.service';
import { setHRLoading } from '.';
import * as type from '../types/types';
import { toast } from 'react-toastify';

export const fetchDepartments = () => async (dispatch) => {
   dispatch(setHRLoading(true));
   try {
      const { data } = await fetchDeptartments();
      dispatch({
         type: type.GET_DEPARTMENT,
         payload: data,
      });
      dispatch(setHRLoading(false));
   } catch (e) {
      dispatch(setHRLoading(false));
      // toast.error(e?.message || 'Unable to fetch departments');
   }
};

export const createDepartment = (data) => async (dispatch) => {
   dispatch({
      type: type.CREATE_DEPARTMENT_START,
      payload: true,
   });
   try {
      const { success, message } = await createDept(data);
      dispatch({
         type: type.CREATE_DEPARTMENT,
      });
      if (success) {
         dispatch({
            type: type.CREATE_DEPARTMENT_START,
            payload: false,
         });
         toast.success(message);
         dispatch(fetchDepartments());
      }
   } catch (e) {
      dispatch({
         type: type.CREATE_DEPARTMENT_START,
         payload: false,
      });
      toast.error(e?.message || 'Unable to create departments');
   }
};
