import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AuthRoute from './AuthRoutes';
import Splash from 'layout/Splash';
import { usePath } from 'hooks/usePath';
import Lazy from 'layout/Lazy';
import Page404 from 'pages/404_page/Page_404';
import LocationProvider from './LocationProvider';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActions } from 'modules/humanresource/redux/action/actions';
import { fetchDepartments } from 'modules/humanresource/redux/action/department';
import { fetchJobTitles } from 'modules/humanresource/redux/action/jobs';
import { fetchLocations } from 'modules/humanresource/redux/action/station';
import { fetchModules } from 'modules/humanresource/redux/action/modules';
import { getAirlines } from 'modules/sprime/redux/action/flights';
import { departments } from 'helpers/enums';

//Routes
const Login = lazy(() => import('pages/Login/Login'));
const Reset = lazy(() => import('pages/Login/ResetPassword'));
const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const Profile = lazy(() => import('pages/profile/Profile'));
const Support = lazy(() => import('pages/support/Support'));

//HR Routes
const HRDashboard = lazy(() => import('modules/humanresource/pages/dashboard/Dashboard'));
const Employees = lazy(() => import('modules/humanresource/pages/users/Users'));
const EditEmployee = lazy(() => import('modules/humanresource/pages/users/EditEmployees'));
const ViewEmployee = lazy(() => import('modules/humanresource/pages/users/ViewEmployee'));
const ManagePermission = lazy(() => import('modules/humanresource/pages/ManagePermission'));
const Archive = lazy(() => import('modules/humanresource/pages/Archive'));

//SPrime Routes
const SPrimeDashboard = lazy(() => import('modules/sprime/pages/dashboard/Dashboard'));

const FlightLog = lazy(() => import('modules/sprime/pages/flight-log/FlightLog'));
const FLightLogDrafts = lazy(() => import('modules/sprime/pages/flight-log/FLightLogDrafts'));
const CreateFlightLog = lazy(() => import('modules/sprime/pages/flight-log/CreateFlightLog'));
const EditFlightLog = lazy(() => import('modules/sprime/pages/flight-log/EditFlightLog'));
const ViewFlightLog = lazy(() => import('modules/sprime/pages/flight-log/ViewFlightLog'));
const ViewDamagedBags = lazy(() => import('modules/sprime/pages/damaged-bags/ViewDamagedBags'));

const IncidentReport = lazy(() => import('modules/sprime/pages/incident-report/IncidentReport'));
const CreateIncidentReport = lazy(
   () => import('modules/sprime/pages/incident-report/CreateIncidentReport'),
);
const EditIncidentReport = lazy(
   () => import('modules/sprime/pages/incident-report/EditIncidentReport'),
);
const ViewIncidentReport = lazy(
   () => import('modules/sprime/pages/incident-report/ViewIncidentReport'),
);

const ScheduledFlights = lazy(
   () => import('modules/sprime/pages/flight-schedule/ScheduledFlights'),
);
const ApprovedFlights = lazy(() => import('modules/sprime/pages/flight-schedule/ApprovedFlights'));
const FlightHistory = lazy(() => import('modules/sprime/pages/flight-schedule/FlightHistory'));
const CreateFlightSchedule = lazy(
   () => import('modules/sprime/pages/flight-schedule/CreateFlightSchedule'),
);
const EditFlightSchedule = lazy(
   () => import('modules/sprime/pages/flight-schedule/EditFlightSchedule'),
);
const ViewFlightSchedule = lazy(
   () => import('modules/sprime/pages/flight-schedule/ViewFlightSchedule'),
);

const FlightTools = lazy(() => import('modules/sprime/pages/flight-tools/FlightTools'));
// const StaffSchedule = lazy(() => import('modules/sprime/pages/staff-schedule/StaffSchedule'));

//SahDocs Routes
const DocsDashboard = lazy(() => import('modules/sahdocs/pages/dashboard/Dashboard'));

const Templates = lazy(() => import('modules/sahdocs/pages/templates/Templates'));
const TransportVoucher = lazy(() => import('modules/sahdocs/pages/templates/TransportVoucher'));
const ExpenseVoucher = lazy(() => import('modules/sahdocs/pages/templates/ExpenseVoucher'));
const FacilitiesRepair = lazy(() => import('modules/sahdocs/pages/templates/FacilitiesRepair'));
const WeekendOvertime = lazy(() => import('modules/sahdocs/pages/templates/WeekendOvertime'));
const TouringAdvance = lazy(() => import('modules/sahdocs/pages/templates/TouringAdvance'));
const OfficeMemo = lazy(() => import('modules/sahdocs/pages/templates/OfficeMemo'));
// const CustomTemplate = lazy(() => import('modules/sahdocs/pages/templates/CustomTemplate'));
const CashAdvance = lazy(() => import('modules/sahdocs/pages/templates/CashAdvance'));
const Manuals = lazy(() => import('modules/sahdocs/pages/manuals/Manuals'));
const AddManual = lazy(() => import('modules/sahdocs/pages/manuals/AddManual'));
const ViewManual = lazy(() => import('modules/sahdocs/pages/manuals/ViewManual'));
const Certifications = lazy(() => import('modules/sahdocs/pages/certifications/Certifications'));
const AddCertification = lazy(
   () => import('modules/sahdocs/pages/certifications/AddCertification'),
);
const ViewCertification = lazy(
   () => import('modules/sahdocs/pages/certifications/ViewCertification'),
);
const Track = lazy(() => import('modules/sahdocs/pages/track/Track'));
const MyDocs = lazy(() => import('modules/sahdocs/pages/myDocs/MyDocs'));
const ViewDocument = lazy(() => import('modules/sahdocs/pages/myDocs/ViewDocument'));
const Licenses = lazy(() => import('modules/sahdocs/pages/legal/Licenses'));
const Contracts = lazy(() => import('modules/sahdocs/pages/legal/Contracts'));
const AddLicense = lazy(() => import('modules/sahdocs/pages/legal/AddLicense'));
const AddContract = lazy(() => import('modules/sahdocs/pages/legal/AddContract'));
const ViewContract = lazy(() => import('modules/sahdocs/pages/legal/ViewContract'));
const ViewLicense = lazy(() => import('modules/sahdocs/pages/legal/ViewLicense'));

const AppRoutes = () => {
   const { isAuth } = useSelector((store) => store.auth);
   const location = useLocation();
   const { path } = usePath(location.pathname);
   let Initializer = Splash;
   if (path[1] === '' || (path[1] === 'dashboard' && path.length === 2)) {
      Initializer = Splash;
   } else if (path[1] === 'human-resources' && path.length > 2) {
      Initializer = Lazy;
   } else if ((path[1] === 'human-resources' || path[1] === 'docs') && path.length === 2) {
      Initializer = Splash;
   }

   const dispatch = useDispatch();

   useEffect(() => {
      if (isAuth && location.pathname !== '/reset-password') {
         dispatch(fetchActions());
         dispatch(fetchDepartments());
         dispatch(fetchJobTitles());
         dispatch(fetchLocations());
         dispatch(fetchModules());
         dispatch(getAirlines());
      }
   }, [isAuth]);

   return (
      <Suspense fallback={<Initializer />}>
         <LocationProvider>
            <Routes location={location} key={location.key}>
               <Route path="/">
                  <Route index element={<Splash />} />
                  <Route path="login" element={<Login />} />
                  <Route path="reset-password" element={<Reset />} />
                  <Route
                     path="dashboard"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Lazy}>
                           <Dashboard />
                        </AuthRoute>
                     }
                  />

                  <Route
                     path="profile"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <Profile />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="support"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <Support />
                        </AuthRoute>
                     }
                  />
               </Route>

               {/* Human Resources */}
               <Route path="/human-resources">
                  <Route
                     path="dashboard"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <HRDashboard />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="employees"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Lazy}>
                           <Employees />
                        </AuthRoute>
                     }
                  />
                  {/* <Route
                     path="add-employee"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Lazy}>
                           <AddEmployees />
                        </AuthRoute>
                     }
                  /> */}
                  <Route
                     path="edit-employee/:id"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Lazy}>
                           <EditEmployee />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="view-employee/:id"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Lazy}>
                           <ViewEmployee />
                        </AuthRoute>
                     }
                  />

                  <Route
                     path="permissions"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Lazy}>
                           <ManagePermission />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="Archive"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Lazy}>
                           <Archive />
                        </AuthRoute>
                     }
                  />
               </Route>

               {/* Sprime */}
               <Route path="/sprime">
                  <Route
                     path="dashboard"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <SPrimeDashboard />
                        </AuthRoute>
                     }
                  />

                  <Route path="flight-log">
                     <Route
                        path="logs"
                        element={
                           <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                              <FlightLog />
                           </AuthRoute>
                        }
                     />
                     <Route
                        path="drafts"
                        element={
                           <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                              <FLightLogDrafts />
                           </AuthRoute>
                        }
                     />
                  </Route>
                  <Route
                     path="flight-log/create/:id"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <CreateFlightLog />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="flight-log/edit/:id"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <EditFlightLog />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="flight-log/view/:id"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <ViewFlightLog />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="damaged-bags/:id/:logId"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <ViewDamagedBags />
                        </AuthRoute>
                     }
                  />

                  <Route
                     path="incidence-report"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <IncidentReport />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="incidence-report/create/:id"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <CreateIncidentReport />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="incidence-report/edit/:id"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <EditIncidentReport />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="incidence-report/view/:id"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <ViewIncidentReport />
                        </AuthRoute>
                     }
                  />

                  {/* <Route
              path="staff-schedule"
              element={
                <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                  <StaffSchedule />
                </AuthRoute>
              }
            /> */}

                  <Route path="flight-schedule">
                     <Route
                        path="create"
                        element={
                           <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                              <CreateFlightSchedule />
                           </AuthRoute>
                        }
                     />
                     <Route
                        path="edit/:id"
                        element={
                           <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                              <EditFlightSchedule />
                           </AuthRoute>
                        }
                     />
                     <Route
                        path="view/:id"
                        element={
                           <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                              <ViewFlightSchedule />
                           </AuthRoute>
                        }
                     />
                     <Route
                        path="scheduled"
                        element={
                           <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                              <ScheduledFlights />
                           </AuthRoute>
                        }
                     />
                     <Route
                        path="approved"
                        element={
                           <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                              <ApprovedFlights />
                           </AuthRoute>
                        }
                     />
                     <Route
                        path="history"
                        element={
                           <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                              <FlightHistory />
                           </AuthRoute>
                        }
                     />
                  </Route>

                  <Route
                     path="tools"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <FlightTools />
                        </AuthRoute>
                     }
                  />
               </Route>

               {/* SahDocs */}
               <Route path="/docs">
                  <Route
                     path="dashboard"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <DocsDashboard />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="templates"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <Templates />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="templates/transport-voucher"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <TransportVoucher />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="templates/expense-voucher"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <ExpenseVoucher />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="templates/facilities-repair"
                     element={
                        <AuthRoute isAuth={isAuth} role={[departments.ADMIN]} lazy={Splash}>
                           <FacilitiesRepair />
                        </AuthRoute>
                     }
                  />
                  {/* <Route
                     path="templates/weekend-overtime"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <WeekendOvertime />
                        </AuthRoute>
                     }
                  /> */}
                  <Route
                     path="templates/touring-advance"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <TouringAdvance />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="templates/memo"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <OfficeMemo />
                        </AuthRoute>
                     }
                  />
                  {/* <Route
                     path="templates/custom"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <CustomTemplate />
                        </AuthRoute>
                     }
                  /> */}
                  <Route
                     path="templates/cash-advance-retirement"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <CashAdvance />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="documents"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <MyDocs />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="documents/view/:id/:movementId/:approvalId"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <ViewDocument />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="documents/view/:id"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <ViewDocument />
                        </AuthRoute>
                     }
                  />

                  <Route
                     path="manuals"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <Manuals />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="manuals/new"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <AddManual />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="manuals/view/:id"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <ViewManual />
                        </AuthRoute>
                     }
                  />

                  <Route
                     path="certifications"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <Certifications />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="certifications/new"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <AddCertification />
                        </AuthRoute>
                     }
                  />
                  <Route
                     path="certifications/view/:id"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <ViewCertification />
                        </AuthRoute>
                     }
                  />

                  <Route
                     path="track"
                     element={
                        <AuthRoute isAuth={isAuth} role={[]} lazy={Splash}>
                           <Track />
                        </AuthRoute>
                     }
                  />

                  <Route path="legal">
                     <Route
                        path="contracts"
                        element={
                           <AuthRoute
                              isAuth={isAuth}
                              role={[departments.LEGAL, departments.MD_CEO]}
                              lazy={Splash}
                           >
                              <Contracts />
                           </AuthRoute>
                        }
                     />
                     <Route
                        path="contracts/new"
                        element={
                           <AuthRoute
                              isAuth={isAuth}
                              role={[departments.LEGAL, departments.MD_CEO]}
                              lazy={Splash}
                           >
                              <AddContract />
                           </AuthRoute>
                        }
                     />
                     <Route
                        path="contracts/view/:id"
                        element={
                           <AuthRoute
                              isAuth={isAuth}
                              role={[departments.LEGAL, departments.MD_CEO]}
                              lazy={Splash}
                           >
                              <ViewContract />
                           </AuthRoute>
                        }
                     />
                     <Route
                        path="licenses"
                        element={
                           <AuthRoute
                              isAuth={isAuth}
                              role={[departments.LEGAL, departments.MD_CEO]}
                              lazy={Splash}
                           >
                              <Licenses />
                           </AuthRoute>
                        }
                     />
                     <Route
                        path="licenses/new"
                        element={
                           <AuthRoute
                              isAuth={isAuth}
                              role={[departments.LEGAL, departments.MD_CEO]}
                              lazy={Splash}
                           >
                              <AddLicense />
                           </AuthRoute>
                        }
                     />
                     <Route
                        path="licenses/view/:id"
                        element={
                           <AuthRoute
                              isAuth={isAuth}
                              role={[departments.LEGAL, departments.MD_CEO]}
                              lazy={Splash}
                           >
                              <ViewLicense />
                           </AuthRoute>
                        }
                     />
                  </Route>
               </Route>

               <Route path="*" element={<Page404 />} />
            </Routes>
         </LocationProvider>
      </Suspense>
   );
};

export default AppRoutes;
