// eslint-disable-next-line no-undef
const { NODE_ENV, REACT_APP_FLIGHT_PROD, REACT_APP_FLIGHT_DEV, REACT_APP_FLIGHT_TEST } = process.env;

const env = NODE_ENV;

const development = {
  api: {
    uri: REACT_APP_FLIGHT_DEV || REACT_APP_FLIGHT_TEST,
  },
};
const test = {
  api: {
    uri: REACT_APP_FLIGHT_TEST,
  },
};
const production = {
  api: {
    uri: REACT_APP_FLIGHT_PROD,
  },
};

const stage = {
  development,
  test,
  production,
};

export const flightEnvironment = stage[env];
