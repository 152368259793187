import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';

const LocationProvider = ({ children }) => {
  return <AnimatePresence>{children}</AnimatePresence>;
};

export default LocationProvider;

LocationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
};
