import * as type from '../types/types';
import {
  archiveStatus,
  createEmployee,
  deactivateEmployees,
  deleteEmployee,
  editEmployee,
  fetchArchivedEmployees,
  fetchEmployee,
  fetchEmployees,
  updateStatus,
  uploadEmployees,
} from 'modules/humanresource/services/employees.service';
import { toast } from 'react-toastify';

export const setHRLoading = (loading) => (dispatch) => {
  dispatch({
    type: type.LOADING,
    payload: loading,
  });
};

export const clearError = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_ERROR,
    payload: null,
  });
};

export const clearSuccess = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_SUCCESS,
    payload: null,
  });
};

export const getEmployees = (page, limit, name) => async (dispatch) => {
  dispatch(setHRLoading(true));
  try {
    const { data } = await fetchEmployees(page, limit, name);
    dispatch({
      type: type.GET_EMPLOYEES,
      payload: data,
    });
    dispatch(setHRLoading(false));
  } catch (e) {
    dispatch(setHRLoading(false));
    toast.error(e?.message || 'Unable to get employee');
  }
};

export const getArchivedEmployees = (page, limit, name) => async (dispatch) => {
  dispatch(setHRLoading(true));
  try {
    const { data } = await fetchArchivedEmployees(page, limit, name);
    dispatch({
      type: type.GET_ARCHIVED_EMPLOYEES,
      payload: data,
    });
    dispatch(setHRLoading(false));
  } catch (e) {
    dispatch(setHRLoading(false));
    toast.error(e?.message || 'Unable to get archived employees');
  }
};

export const bulkDeactivateEmployees = (ids, page, limit, name, handleSuccess) => async (dispatch) => {
  try {
    const { success, message } = await deactivateEmployees(ids);
    dispatch({
      type: type.DEACTIVATE_EMPLOYEES,
    });

    if (success) {
      toast.success(message);
      dispatch(getEmployees(page, limit, name));
      if (handleSuccess) {
        handleSuccess();
      }
    }
  } catch (e) {
    toast.error(e?.message || 'Unable to deactivate employee');
  }
};

export const bulkArchiveEmployees = (ids, page, limit, name, handleSuccess) => async (dispatch) => {
  try {
    const { success, message } = await archiveStatus(ids);
    dispatch({
      type: type.DELETE_EMPLOYEES,
    });

    if (success) {
      toast.success(message);
      dispatch(getEmployees(page, limit, name));
      dispatch(getArchivedEmployees(page, limit, name));
      if (handleSuccess) {
        handleSuccess();
      }
    }
  } catch (e) {
    toast.error(e?.message || 'Unable to archive employees');
  }
};

export const bulkUploadEmployees = (data, handleSuccess) => async (dispatch) => {
  dispatch({ type: type.UPLOAD_EMPLOYEE });
  try {
    const { success, message } = await uploadEmployees(data);

    if (success) {
      dispatch({ type: type.UPLOAD_EMPLOYEE_SUCCESS });
      toast.success(message);
      if (handleSuccess) {
        handleSuccess();
      }
    }
  } catch (e) {
    toast.error(e?.message || 'Unable to upload file');
    dispatch({ type: type.UPLOAD_EMPLOYEE_ERROR });
  }
};

export const getEmployee = (id) => async (dispatch) => {
  dispatch({
    type: type.VIEW_EMPLOYEE_START,
    payload: true,
  });
  try {
    const { data, success } = await fetchEmployee(id);
    dispatch({
      type: type.VIEW_EMPLOYEE,
      payload: data,
    });
    if (success) {
      dispatch({
        type: type.VIEW_EMPLOYEE_START,
        payload: false,
      });
    }
  } catch (e) {
    dispatch({
      type: type.VIEW_EMPLOYEE_START,
      payload: false,
    });
    toast.error(e?.message || 'Unable to get employee');
  }
};

export const updateEmployee = (id, update) => async (dispatch) => {
  dispatch({
    type: type.EDIT_EMPLOYEE_START,
    payload: true,
  });
  try {
    const { message, success } = await editEmployee(id, update);
    dispatch({
      type: type.EDIT_EMPLOYEE,
    });
    if (success) {
      dispatch({
        type: type.EDIT_EMPLOYEE_START,
        payload: false,
      });
      toast.success(message);
      dispatch(getEmployee(id));
    }
  } catch (e) {
    dispatch({
      type: type.EDIT_EMPLOYEE_START,
      payload: false,
    });
    toast.error(e?.message || 'Unable to update employee');
  }
};

export const createNewEmployee = (employee, handleSuccess) => async (dispatch) => {
  dispatch({
    type: type.CREATE_EMPLOYEE_START,
    payload: true,
  });
  try {
    const { success, message } = await createEmployee(employee);
    if (success) {
      dispatch({
        type: type.CREATE_EMPLOYEE_START,
        payload: false,
      });
      toast.success(message);
      handleSuccess();
    }
  } catch (e) {
    dispatch({
      type: type.CREATE_EMPLOYEE_START,
      payload: false,
    });
    toast.error(e?.message || 'Unable to onboard employee');
  }
};

export const updateEmployeeStatus = (id, status) => async (dispatch) => {
  dispatch(setHRLoading(true));
  try {
    const { message, success } = await updateStatus(id, status);
    dispatch({
      type: type.DEACTIVATE_EMPLOYEES,
    });
    if (success) {
      toast.success(message);
      dispatch(getEmployee(id));
    }
    dispatch(setHRLoading(false));
  } catch (e) {
    dispatch(setHRLoading(false));
    toast.error(e?.message || 'Unable to update employee');
  }
};

export const softDeleteEmployee = (id, page, limit, name) => async (dispatch) => {
  dispatch(setHRLoading(true));
  try {
    const { message, success } = await deleteEmployee(id);
    dispatch({
      type: type.DELETE_EMPLOYEES,
    });
    if (success) {
      dispatch({
        type: type.SUCCESS,
        payload: message,
      });
      toast.success(message);
      dispatch(setHRLoading(false));
    }
    dispatch(getEmployees(page, limit, name));
  } catch (e) {
    dispatch(setHRLoading(false));
    toast.error(e?.message || 'Unable to delete employee');
  }
};
