import { toast } from 'react-toastify';
import FLIGHT_API from 'utils/axios-flight';

const API = FLIGHT_API;

export const fetchFlightSchedule = async ({
   page,
   limit,
   mode,
   status,
   code,
   carrier,
   startDate,
   endDate,
   defaultFlight,
   flightNumber,
}) => {
   try {
      let url = `/schedules/daily?page=${page}&limit=${limit}&mode=${mode}&status=${status}`;

      if (code) {
         url += `&airport=${code}`;
      }
      if (carrier) {
         url += `&carrier=${carrier}`;
      }
      if (startDate) {
         url += `&startDate=${startDate}`;
      }
      if (endDate) {
         url += `&endDate=${endDate}`;
      }
      if (defaultFlight) {
         url += `&default=${defaultFlight}`;
      }
      if (flightNumber) {
         url += `&flightNumber=${flightNumber}`;
      }

      const res = await API.get(url);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const fetchSingleFlight = async (id) => {
   try {
      const res = await API.get(`/schedules/${id}`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const makeFlightSchedule = async ({ payload }) => {
   try {
      const res = await API.post(`/schedules/new`, payload);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const updateFlightSchedule = async ({ payload, id }) => {
   try {
      const res = await API.patch(`/schedules/daily/${id}`, payload);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const fetchAirlines = async () => {
   try {
      const res = await API.get(`/airlines`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

//Flight Logs
export const fetchFlightLogs = async ({
   page,
   limit,
   flightSupervisor,
   operator,
   modeOfOperation,
   startDate,
   endDate,
   flightNumber,
   isDraft,
}) => {
   try {
      let url = `/flight-log?page=${page}&limit=${limit}&isDraft=${isDraft}`;

      if (flightSupervisor) {
         url += `&flightSupervisor=${flightSupervisor}`;
      }
      if (operator) {
         url += `&operator=${operator}`;
      }
      if (modeOfOperation) {
         url += `&modeOfOperation=${modeOfOperation}`;
      }
      if (startDate) {
         url += `&startDate=${startDate}`;
      }
      if (endDate) {
         url += `&endDate=${endDate}`;
      }
      if (flightNumber) {
         url += `&flightNumber=${flightNumber}`;
      }

      const res = await API.get(url);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const fetchSingleFlightLog = async (id) => {
   try {
      const res = await API.get(`/flight-log/${id}`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const deleteFlightLog = async (id) => {
   try {
      const res = await API.delete(`/flight-log/${id}`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const makeFlightLog = async ({ payload }) => {
   try {
      const res = await API.post(`/flight-log`, payload);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const updateFlightLog = async ({ payload, id }) => {
   try {
      const res = await API.patch(`/flight-log/${id}`, payload);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

//Flight Logs
export const fetchReports = async ({
   page,
   limit,
   nameOfSupervisor,
   damageCaused,
   operator,
   startDate,
   endDate,
}) => {
   try {
      let url = `/incidence-report?page=${page}&limit=${limit}`;

      if (nameOfSupervisor) {
         url += `&nameOfSupervisor=${nameOfSupervisor}`;
      }
      if (operator) {
         url += `&operator=${operator}`;
      }
      if (damageCaused) {
         url += `&damageCaused=${damageCaused}`;
      }
      if (startDate) {
         url += `&startDate=${startDate}`;
      }
      if (endDate) {
         url += `&endDate=${endDate}`;
      }

      const res = await API.get(url);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const fetchSingleReport = async (id) => {
   try {
      const res = await API.get(`/incidence-report/${id}`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const makeReport = async ({ payload, id }) => {
   try {
      const res = await API.post(`/incidence-report/${id}`, payload);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const updateReport = async ({ payload, id, logId }) => {
   try {
      const res = await API.patch(`/incidence-report/${id}/${logId}`, payload);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const addDamagedBags = async ({ payload }) => {
   try {
      const res = await API.post(`/damaged-bag/new`, payload);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const fetchDamagedBags = async ({ page, limit, flightScheduleId, flightLogId }) => {
   try {
      const res = await API.get(
         `/damaged-bag?page=${page}&limit=${limit}&flightScheduleId=${flightScheduleId}&flightLogId=${flightLogId}`,
      );

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const uploadSchedules = async (data) => {
   try {
      const res = await API.post(`/schedules/upload-schedules`, data, {
         headers: { 'Content-Type': 'multipart/form-data' },
      });
      toast.success('Schedules uploaded successfully');
      return res?.data;
   } catch (e) {
      toast.error(e?.response?.data.message || 'Unable to upload schedule');
      throw e?.response?.data;
   }
};

export const fetchFlightStatistics = async () => {
   try {
      const res = await API.get(`/stats`);

      return res?.data?.data;
   } catch (e) {
      toast.error(e?.response?.data.message || 'Unable to fetch statistics');
      throw e?.response?.data;
   }
};

export const calculateEmployees = async ({ payload }) => {
   try {
      const res = await API.post(`/schedules/personnel`, payload);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};
