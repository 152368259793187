import API from 'utils/Axios';

export const getHome = async () => {
   try {
      const res = await API.get(`/`);

      return res;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const loginUser = async (data) => {
   try {
      const res = await API.post(`/auth/login`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const logoutUser = async () => {
   try {
      const res = await API.get(`/auth/logout`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const verifyAccount = async (data) => {
   try {
      const res = await API.post(`/auth/verify-account`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const forgotPassword = async (data) => {
   try {
      const res = await API.post(`/auth/request-otp`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const verifyOtp = async (data) => {
   try {
      const res = await API.post(`/auth/verify-otp`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const resetPassword = async (data) => {
   try {
      const res = await API.post(`/auth/reset-password`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const resetPasswordFirstTimer = async (data, token) => {
   try {
      const res = await API.post(`/auth/set-password`, data, {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      });

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const fetchProfile = async () => {
   try {
      const res = await API.get(`/auth/profile`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const fetchUserPermissions = async (id) => {
   const apiKey = localStorage.getItem('apiKey');

   try {
      const res = await API.get(`/access/${id}`, { headers: { 'X-SAHCOAPI-KEY': apiKey } });

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const resendOTP = async () => {
   try {
      const res = await API.get(`/auth/resend-otp`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const noAuthResendOTP = async (data) => {
   try {
      const res = await API.post(`/auth/resend-otp/forgot`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const authChangePassword = async (data) => {
   try {
      const res = await API.post(`/auth/change-password`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const changeProfileAvatar = async (data) => {
   try {
      const res = await API.put('/employees/profile', data, {
         headers: {
            'Content-Type': 'multipart/form-data',
            Accept: '*/*',
         },
      });

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const SupportSubmit = async (data) => {
   try {
      const res = await API.post(`/support`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const fetchQuote = async () => {
   try {
      const res = await API.get(`/alerts/quote`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const createSecurePin = async (data) => {
   try {
      const res = await API.post(`/auth/secure-pin`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const editSecurePin = async (data) => {
   try {
      const res = await API.patch(`/auth/secure-pin`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const verifySecurePin = async (data) => {
   try {
      const res = await API.post(`/auth/verify-secure-pin`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};
