import API from 'utils/Axios';

export const fetchEmployeeActions = async () => {
  try {
    const res = await API.get(`/actions`);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const copyPermission = async (data) => {
  try {
    const res = await API.post(`/access/duplicate`, data);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const createAction = async (data) => {
  try {
    const res = await API.post(`/actions/new`, data);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
