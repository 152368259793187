import React, { useEffect, useState } from 'react';
import { SplashContainer } from './styles';
import logo from 'assets/images/data.svg';
import splash from 'assets/images/splash_1.svg';
import budget from 'assets/images/Budget logo.svg';
import budgetApp from 'assets/images/budget_1.svg';
import { usePath } from 'hooks/usePath';
import { useLocation, useNavigate } from 'react-router-dom';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Image from './Image';
import { motion } from 'framer-motion';

const Splash = () => {
  const { pathname } = useLocation();
  const { path } = usePath(pathname);
  const navigate = useNavigate();
  const [secs] = useState(5);
  const [image, setImage] = useState(logo);
  const [activeIndex, setActiveIndex] = useState(0);
  const [images] = useState([logo, budget, budgetApp, splash]);
  const [splashText] = useState(['Welcome Onboard', 'Initializing', 'Initializing', 'SAHCO Internals!']);
  const numberOfRange = secs / images.length;

  const updateIndex = (newIndex) => {
    if (newIndex === 0) {
      newIndex = images.length - 1;
    } else if (newIndex >= images.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateIndex(activeIndex + 1);
    }, numberOfRange * 1000);
    setImage(images[activeIndex]);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handleNavigate = () => {
    navigate('/login');
  };
  if (path[1] === '') {
    setTimeout(handleNavigate, 5000);
  }

  return (
    <SplashContainer>
      <motion.div className="lazy__initializer" animate={{ y: [0, 100, 0] }}>
        <CountdownCircleTimer
          isPlaying={true}
          duration={secs}
          size={250}
          strokeWidth={5}
          colors={['#DF7024']}
          colorsTime={[]}
          rotation="counterclockwise"
        >
          {() => <Image logo={image} />}
        </CountdownCircleTimer>
        <p>{splashText[activeIndex]}</p>
      </motion.div>
    </SplashContainer>
  );
};

export default Splash;
