import styled from 'styled-components';

export const FormLogin = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .pass_word {
    display: flex;
    justify-content: right;
    width: 100%;

    .password {
      text-decoration: none;
      color: #7a7a7a;
      font-family: 'Nunito', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12.7461px;
      line-height: 17px;
    }
  }

  .pass {
    text-decoration: none;
    color: #df7024;
    cursor: pointer;
  }

  .button__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    .pass_word {
      .password {
        font-size: 9.46691px;
        line-height: 13px;
        text-align: left;
      }
    }
  }
`;

export const FormButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 86px;
  gap: 10px;
  width: 275px;
  height: 55px;
  background: #df7024;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  white-space: nowrap;

  &:disabled {
    background: #e0e0e0;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(41, 41, 41, 0.3), rgba(41, 41, 41, 0.3)), #df7024;
    border:
      1px solid linear-gradient(0deg, rgba(41, 41, 41, 0.3), rgba(41, 41, 41, 0.3)),
      #df7024;
  }

  a {
    text-decoration: none;
    color: #ffffff;
  }

  @media screen and (max-width: 768px) {
    width: 241px;
    height: 48px;
    padding: 15.5px 104px;
    gap: 7.43px;
    font-size: 16px;
  }
`;
