import API from 'utils/Axios';

export const getNotifications = async (page, limit) => {
  try {
    const res = await API.get(`/alerts?page=${page}&limit=${limit}`);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getNotification = async () => {
  try {
    const res = await API.get(`/alerts/bell`);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const readNotification = async (id) => {
  try {
    const res = await API.patch(`/alerts/${id}`);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const readAllNotifications = async () => {
  try {
    const res = await API.patch(`/alerts/read`);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
