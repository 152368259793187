import API from 'utils/Axios';

export const fetchStations = async () => {
  try {
    const res = await API.get(`/stations`);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const createStation = async (data) => {
  try {
    const res = await API.post(`/stations/new`, data);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
