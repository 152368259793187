import { createStation, fetchStations } from 'modules/humanresource/services/stations.service';
import { setHRLoading } from '.';
import * as type from '../types/types';
import { toast } from 'react-toastify';

export const fetchLocations = () => async (dispatch) => {
   dispatch(setHRLoading(true));
   try {
      const { data } = await fetchStations();
      dispatch({
         type: type.GET_STATIONS,
         payload: data,
      });
      dispatch(setHRLoading(false));
   } catch (e) {
      dispatch(setHRLoading(false));
      // toast.error(e?.message || 'Unable to fetch locations');
   }
};

export const createLocations = (data) => async (dispatch) => {
   dispatch({
      type: type.CREATE_STATION_START,
      payload: true,
   });
   try {
      const { success, message } = await createStation(data);
      if (success) {
         dispatch({
            type: type.CREATE_STATION,
         });
         toast.success(message);
      }
      dispatch({
         type: type.CREATE_STATION_START,
         payload: false,
      });
   } catch (e) {
      dispatch({
         type: type.CREATE_STATION_START,
         payload: false,
      });
      toast.error(e?.message || 'Unable to create location');
   }
};
