import API from 'utils/Axios';

export const fetchEmployees = async (page, limit, name) => {
   try {
      const url = name
         ? `/employees?page=${page}&limit=${limit}&name=${name}`
         : `/employees?page=${page}&limit=${limit}`;
      const res = await API.get(url);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const fetchArchivedEmployees = async (page, limit, name) => {
   try {
      const res = await API.get(`/employees/archived?page=${page}&limit=${limit}&name=${name}`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const deactivateEmployees = async (data) => {
   try {
      const res = await API.post(`/employees/status`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const fetchEmployee = async (id) => {
   try {
      const res = await API.get(`/employees/${id}`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const editEmployee = async (id, data) => {
   try {
      const res = await API.patch(`/employees/${id}/assign-permissions`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const createEmployee = async (data) => {
   try {
      const res = await API.post(`/employees/new`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const updateStatus = async (id, data) => {
   try {
      const res = await API.patch(`/employees/${id}/status`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const deleteEmployee = async (id) => {
   try {
      const res = await API.delete(`/employees/${id}/delete`);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const archiveStatus = async (data) => {
   try {
      const res = await API.patch(`/employees/delete`, data);

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};

export const uploadEmployees = async (data) => {
   try {
      const res = await API.post(`/employees/upload`, data, {
         headers: { 'Content-Type': 'multipart/form-data' },
      });

      return res?.data;
   } catch (e) {
      throw e?.response?.data;
   }
};
