// Flight Schedules
export const GET_FLIGHTS = 'GET_FLIGHTS';
export const GET_FLIGHTS_SUCCESS = 'GET_FLIGHTS_SUCCESS';
export const GET_FLIGHTS_ERROR = 'GET_FLIGHTS_ERROR';

export const GET_SINGLE_FLIGHT = 'GET_SINGLE_FLIGHT';
export const CLEAR_SINGLE_FLIGHT = 'CLEAR_SINGLE_FLIGHT';
export const GET_SINGLE_FLIGHT_SUCCESS = 'GET_SINGLE_FLIGHT_SUCCESS';
export const GET_SINGLE_FLIGHT_ERROR = 'GET_SINGLE_FLIGHT_ERROR';

export const CLEAR_FLIGHT_DATA = 'CLEAR_FLIGHT_DATA';
export const CREATE_FLIGHT = 'CREATE_FLIGHT';
export const CREATE_FLIGHT_SUCCESS = 'CREATE_FLIGHT_SUCCESS';
export const CREATE_FLIGHT_ERROR = 'CREATE_FLIGHT_ERROR';

export const FETCH_AIRLINES = 'FETCH_AIRLINES';
export const FETCH_AIRLINES_SUCCESS = 'FETCH_AIRLINES_SUCCESS';
export const FETCH_AIRLINES_ERROR = 'FETCH_AIRLINES_ERROR';

//Flight Logs
export const GET_FLIGHT_LOGS = 'GET_FLIGHT_LOGS';
export const GET_FLIGHT_LOGS_SUCCESS = 'GET_FLIGHT_LOGS_SUCCESS';
export const GET_FLIGHT_LOGS_ERROR = 'GET_FLIGHT_LOGS_ERROR';

export const GET_SINGLE_FLIGHT_LOG = 'GET_SINGLE_FLIGHT_LOG';
export const GET_SINGLE_FLIGHT_LOG_SUCCESS = 'GET_SINGLE_FLIGHT_LOG_SUCCESS';
export const GET_SINGLE_FLIGHT_LOG_ERROR = 'GET_SINGLE_FLIGHT_LOG_ERROR';

export const CREATE_FLIGHT_LOG = 'CREATE_FLIGHT_LOG';
export const CREATE_FLIGHT_LOG_SUCCESS = 'CREATE_FLIGHT_LOG_SUCCESS';
export const CREATE_FLIGHT_LOG_ERROR = 'CREATE_FLIGHT_LOG_ERROR';

//Incident Report
export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_ERROR = 'GET_REPORTS_ERROR';

export const GET_SINGLE_REPORT = 'GET_SINGLE_REPORT';
export const GET_SINGLE_REPORT_SUCCESS = 'GET_SINGLE_REPORT_SUCCESS';
export const GET_SINGLE_REPORT_ERROR = 'GET_SINGLE_REPORT_ERROR';

export const CREATE_REPORT = 'CREATE_REPORT';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_ERROR = 'CREATE_REPORT_ERROR';

//Damaged Bags
export const ADD_DAMAGED_BAGS = 'ADD_DAMAGED_BAGS';
export const ADD_DAMAGED_BAGS_SUCCESS = 'ADD_DAMAGED_BAGS_SUCCESS';
export const ADD_DAMAGED_BAGS_ERROR = 'ADD_DAMAGED_BAGS_ERROR';

export const GET_DAMAGED_BAGS = 'GET_DAMAGED_BAGS';
export const GET_DAMAGED_BAGS_SUCCESS = 'GET_DAMAGED_BAGS_SUCCESS';
export const GET_DAMAGED_BAGS_ERROR = 'GET_DAMAGED_BAGS_ERROR';
