import sahcoLogo from 'assets/images/sifax-sahco-logo-brand-1-removebg-preview 1.svg';
import page404logo from 'assets/images/page404logo.svg';
import React from 'react';
import { Page404Container } from './Styles';
import { FormButton } from 'components/Form/styles';
import { useNavigate } from 'react-router-dom';

const Page404 = () => {
  const navigate = useNavigate();

  return (
    <Page404Container>
      <div className="navbar_logo">
        <img src={sahcoLogo} alt="SAHCO LOGO" />
      </div>
      <div className="not_found_container">
        <div className="imgContainer">
          <img src={page404logo} alt="Not Found" />
        </div>
        <h2>Page Not Found</h2>
      </div>
      <FormButton className="formBtn" onClick={() => navigate(-1)}>
        Back to previous page
      </FormButton>
    </Page404Container>
  );
};

export default Page404;
