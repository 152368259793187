/* eslint-disable no-undef */
import axios from 'axios';
import { environment } from 'config/environment';
// import { invalidApiKeyCodes } from 'helpers/constants';

const {
   api: { uri },
} = environment;

const API = axios.create({
   baseURL: uri,
   withCredentials: true,
   headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
   },
});

API.interceptors.request.use((request) => {
   return request;
});

// API.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (invalidApiKeyCodes.includes(error.response.status)) {
//       localStorage.clear();
//       window.location.href = '/login';
//     }
//     return Promise.reject(error);
//   },
// );

export default API;
