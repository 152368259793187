import API from 'utils/Axios';

export const getModules = async () => {
  try {
    const res = await API.get(`/modules`);

    return res?.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
