import {
   fetchProfile,
   fetchUserPermissions,
   getHome,
   logoutUser,
   noAuthResendOTP,
   resendOTP,
} from 'services/auth.service';
import { getNotifications, readNotification } from 'services/notification.service';
import * as type from '../types/types';
import { toast } from 'react-toastify';

export const setLoading = (loading) => (dispatch) => {
   dispatch({
      type: type.LOADING,
      payload: loading,
   });
};

export const getUserPermissions = (id) => async (dispatch) => {
   dispatch(setLoading(true));
   try {
      const { data } = await fetchUserPermissions(id);
      dispatch({
         type: type.GET_PERMITTED_MODULES,
         payload: data,
      });
      dispatch(setLoading(false));
   } catch (e) {
      dispatch({
         type: type.ERROR,
         payload: 'An error occured' || e?.message,
      });
   }
};

export const signIn = (data) => async (dispatch) => {
   try {
      dispatch({
         type: type.SIGN_IN,
         payload: data,
      });
      dispatch(getUserPermissions(data.permissions));
   } catch (error) {
      dispatch({
         type: type.ERROR,
         payload: 'Unable to set user',
      });
   }
};

export const signOut = () => async (dispatch) => {
   try {
      dispatch({
         type: type.SIGN_OUT,
      });
      const { data } = await logoutUser();
      dispatch({
         type: type.SUCCESS,
         data: data,
      });
   } catch (error) {
      dispatch({
         type: type.ERROR,
         payload: 'An error occured',
      });
   }
};

export const setIsAuth = () => async (dispatch) => {
   dispatch(setLoading(true));
   try {
      const { data } = await fetchProfile();
      dispatch({
         type: type.SET_IS_AUTH,
         payload: data,
      });
      dispatch(setLoading(false));
   } catch (e) {
      dispatch({
         type: type.ERROR,
         payload: 'An error occured' || e?.message,
      });
   }
};

export const fetchNotifications = (page, limit) => async (dispatch) => {
   dispatch(setLoading(true));
   try {
      const { data } = await getNotifications(page, limit);
      dispatch({
         type: type.GET_NOTIFICATIONS,
         payload: data,
      });
      dispatch(setLoading(false));
   } catch (e) {
      // toast.error(e?.message || 'An error occured');
      dispatch({
         type: type.ERROR,
         payload: 'An error occured' || e?.message,
      });
   }
};

export const markAsRead = (id, page, limit) => async (dispatch) => {
   dispatch(setLoading(true));
   try {
      const { success } = await readNotification(id);
      if (success) {
         dispatch(fetchNotifications(page, limit));
         dispatch(setLoading(false));
      }
   } catch (e) {
      // toast.error(e?.message || 'An error occured');
      dispatch({
         type: type.ERROR,
         payload: 'An error occured' || e?.message,
      });
   }
};

export const apiCallError = (data) => async (dispatch) => {
   dispatch({
      type: type.ERROR,
      payload: data,
   });
};

export const apiCallSuccess = (data) => async (dispatch) => {
   dispatch({
      type: type.SUCCESS,
      payload: data,
   });
};

export const setHome = () => async (dispatch) => {
   try {
      const { data } = await getHome();
      if (data) {
         dispatch(apiCallSuccess(data));
      }
   } catch (error) {
      dispatch({
         type: type.ERROR,
         payload: 'Unable to logout user',
      });
   }
};

export const resendOTPAuth = () => async (dispatch) => {
   dispatch(setLoading(true));
   try {
      const { success, data } = await resendOTP();
      if (success) {
         dispatch({
            type: type.REQUEST_OTP,
            data: data,
         });
         dispatch(setLoading(false));
      }
   } catch (e) {
      toast.error(e?.message || 'An error occured');
      dispatch({
         type: type.ERROR,
         payload: e?.message || 'An error occured',
      });
   }
};

export const resendOTPNoAuth = (id) => async (dispatch) => {
   dispatch(setLoading(true));
   try {
      const { success, data } = await noAuthResendOTP(id);
      if (success) {
         dispatch({
            type: type.REQUEST_OTP,
            data: data,
         });
         dispatch(setLoading(false));
      }
   } catch (e) {
      toast.error(e?.message || 'An error occured');
      dispatch({
         type: type.ERROR,
         payload: e?.message || 'An error occured',
      });
   }
};
