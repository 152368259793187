import { initialState } from 'redux/reducers/initialState';
import * as type from '../types/types';

const appReducer = (state = initialState.humanResources, action) => {
  switch (action.type) {
    case type.GET_EMPLOYEES:
      return {
        ...state,
        employees: { ...state.employees, staff: action.payload, success: true },
      };

    case type.GET_ARCHIVED_EMPLOYEES:
      return {
        ...state,
        employees: { ...state.employees, archived: action.payload },
      };

    case type.VIEW_EMPLOYEE:
      return {
        ...state,
        employee: { ...state.employee, staff: action.payload },
      };

    case type.EDIT_EMPLOYEE_START:
    case type.VIEW_EMPLOYEE_START:
      return {
        ...state,
        employee: { ...state.employee, loading: action.payload },
      };

    case type.EDIT_EMPLOYEE_ERROR:
    case type.VIEW_EMPLOYEE_ERROR:
      return {
        ...state,
        employee: { ...state.employee, error: action.payload },
      };

    case type.CREATE_EMPLOYEE_START:
      return {
        ...state,
        employees: { ...state.employees, loading: action.payload },
      };

    case type.CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employees: { ...state.employees, success: action.payload },
      };

    case type.UPLOAD_EMPLOYEE:
      return {
        ...state,
        upload_employees_loading: true,
      };
    case type.UPLOAD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        upload_employees_loading: false,
      };
    case type.UPLOAD_EMPLOYEE_ERROR:
      return {
        ...state,
        upload_employees_loading: false,
      };

    case type.CREATE_ACTION_START:
      return {
        ...state,
        actions: { ...state.actions, loading: action.payload },
      };

    case type.CREATE_DEPARTMENT_START:
      return {
        ...state,
        departments: { ...state.departments, loading: action.payload },
      };

    case type.CREATE_JOB_TITLE_START:
      return {
        ...state,
        jobTitles: { ...state.jobTitles, loading: action.payload },
      };

    case type.CREATE_STATION_START:
      return {
        ...state,
        stations: { ...state.stations, loading: action.payload },
      };

    case type.CREATE_EMPLOYEE_ERROR:
      return {
        ...state,
        employees: { ...state.employees, error: action.payload },
      };

    case type.CREATE_ACTION_ERROR:
      return {
        ...state,
        actions: { ...state.actions, error: action.payload },
      };

    case type.CREATE_DEPARTMENT_ERROR:
      return {
        ...state,
        departments: { ...state.departments, error: action.payload },
      };

    case type.CREATE_JOB_TITLE_ERROR:
      return {
        ...state,
        jobTitles: { ...state.jobTitles, error: action.payload },
      };

    case type.CREATE_STATION_ERROR:
      return {
        ...state,
        stations: { ...state.stations, error: action.payload },
      };

    case type.GET_ACTIONS:
      return {
        ...state,
        actions: { ...state.actions, actions: action.payload },
      };

    case type.GET_MODULES_SUCCESS:
      return {
        ...state,
        modules: action.payload,
      };

    case type.GET_DEPARTMENT:
      return {
        ...state,
        departments: { ...state.departments, dept: action.payload },
      };

    case type.GET_JOB_TITLE:
      return {
        ...state,
        jobTitles: { ...state.jobTitles, jobs: action.payload },
      };

    case type.GET_STATIONS:
      return {
        ...state,
        stations: { ...state.stations, locations: action.payload },
      };

    case type.ERROR:
    case type.CLEAR_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case type.SUCCESS:
    case type.CLEAR_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case type.LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return { ...state };
  }
};

export default appReducer;
