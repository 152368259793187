/* eslint-disable no-undef */
import axios from 'axios';
import { flightEnvironment } from 'config/flight-environment';
// import { invalidApiKeyCodes } from 'helpers/constants';

const {
  api: { uri },
} = flightEnvironment;

const FLIGHT_API = axios.create({
  baseURL: uri,
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
});

FLIGHT_API.interceptors.request.use((request) => {
  const apiKey = localStorage.getItem('apiKey');

  if (apiKey) {
    request.headers['X-SAHCOAPI-KEY'] = apiKey;
  }

  return request;
});

// FLIGHT_API.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (invalidApiKeyCodes.includes(error.response.status)) {
//       localStorage.clear();
//       window.location.href = '/login';
//     }
//     return Promise.reject(error);
//   },
// );

export default FLIGHT_API;
